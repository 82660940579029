<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12" class="py-0">
        <v-card class="px-7 py-7 rounded-lg" id="card-custom">
          <p class="headline-color">Laporan Data Pegawai</p>
          <v-divider />
          <v-row cols="12" xl="12" lg="12" md="12" sm="12" xs="12" class="my-0">
            <v-col>
              <v-radio-group v-model="format" mandatory>
                <v-row>
                  <v-col
                    cols="4"
                    class="pb-0"
                    v-for="(item, index) in formats"
                    :key="index"
                  >
                    <v-radio
                      :value="item.value"
                      class="font-weight-regular"
                      hide-details
                    >
                      <template #label>
                        <span class="subtitle-2 font-weight-regular">{{
                          item.label
                        }}</span>
                      </template>
                    </v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row align="end">
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-autocomplete
                :items="units"
                v-model="unit"
                :loading="loadingUnit"
                placeholder="Unit Utama"
                outlined
                dense
                hide-details
                item-text="singkatan"
                item-value="unit_utama_id"
                clearable
                @change="changeUnit(1)"
                @click:clear="changeUnit(1)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-autocomplete
                :items="units2"
                v-model="unit2"
                :loading="loadingUnit"
                placeholder="Unit Kerja"
                outlined
                dense
                hide-details
                item-text="singkatan"
                item-value="unit_kerja_2_id"
                clearable
                @change="changeUnit(2)"
                @click:clear="changeUnit(2)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-autocomplete
                :items="units3"
                v-model="unit3"
                :loading="loadingUnit"
                placeholder="Unit Kerja"
                outlined
                dense
                hide-details
                item-text="singkatan"
                item-value="unit_kerja_3_id"
                clearable
                @change="changeUnit(3)"
                @click:clear="changeUnit(3)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-autocomplete
                :items="units4"
                v-model="unit4"
                :loading="loadingUnit"
                placeholder="Unit Kerja"
                outlined
                dense
                hide-details
                item-text="singkatan"
                item-value="unit_kerja_4_id"
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col ols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-select
                placeholder="Unit Kerja Group"
                :items="unitsGroup"
                v-model="unit1"
                :loading="loadingUnit"
                outlined
                dense
                hide-details
                clearable
                item-text="nama_uk_group"
                item-value="uk_group_id"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              xl="3"
              lg="3"
              md="6"
              sm="6"
              xs="12"
              class="my-0"
              v-if="format != 'kgb'"
            >
              <v-autocomplete
                :items="months"
                v-model="month"
                placeholder="Bulan"
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-autocomplete
                :items="years"
                v-model="year"
                placeholder="Tahun"
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-btn color="#FBB005" class="px-10" @click="getListEmployee">
                <span class="subtitle-2 text-capitalize">Cari</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer />
            <v-btn
              @click="visibleColumn = true"
              small
              outlined
              class="px-4 font-weight-regular"
              color="#555555"
            >
              <v-icon class="mr-2">view_column</v-icon>
              Sesuaikan Kolom
            </v-btn>
            <v-btn
              small
              outlined
              color="#717171"
              class="mx-2 px-5 text-capitalize"
              :loading="loadingExport"
              @click="exportDataPegawai"
              v-if="employees && employees.length > 0"
            >
              <v-icon class="mr-2">file_download</v-icon>
              Informasi Pegawai
            </v-btn>
          </v-card-actions>
          <v-row>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
              <v-data-table
                id="table-custom"
                class="mt-10"
                multi-sort
                :headers="headers"
                :items="employees"
                :loading="loading"
                :options.sync="options"
                :server-items-length="pagination.totalItem"
                :footer-props="{
                  'items-per-page-options': rowsPerPageItems
                }"
                loading-text="Loading... Please wait"
              >
                <template v-slot:[`item.NIP`]="{ item }">
                  <span
                    class="hover-primary"
                    @click="
                      getToPage('employee.detail', {
                        params: { employeeId: item.NIP }
                      })
                    "
                    >{{ item.NIP }}</span
                  >
                </template>
                <template v-slot:[`item.jabatan_id`]="{ item }">{{
                  item.jabatan
                }}</template>
                <template v-slot:[`item.unit_kerja_2_id`]="{ item }">{{
                  item.unit_kerja_2
                }}</template>
                <template v-slot:[`item.unit_kerja_3_id`]="{ item }">{{
                  item.unit_kerja_3
                }}</template>
                <template v-slot:[`item.unit_kerja_4_id`]="{ item }">{{
                  item.unit_kerja_4
                }}</template>
                <template v-slot:no-data>
                  <ContentNotFound
                    message="Data pegawai dan presensi tidak ada"
                  />
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="visibleColumn" persistent max-width="750">
      <v-card class="pa-5 rounded-lg">
        <v-btn @click="visibleColumn = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          <v-icon class="mr-2">view_column</v-icon>
          Sesuaikan Kolom
        </v-card-text>
        <v-divider />
        <v-row v-for="(groupName, index) in groupNames" :key="index">
          <v-col cols="12" class="pb-0">
            <p class="mb-0">{{ groupName | snakeToUppercase }}</p>
            <v-row>
              <v-col
                cols="3"
                class="pb-0"
                v-for="(item, index2) in headersTemp.filter(
                  data => data.group == groupName
                )"
                :key="index2"
              >
                <v-checkbox
                  v-model="item.active"
                  :value="item.value"
                  hide-details
                  dense
                >
                  <template #label>
                    <span class="subtitle-2 font-weight-regular">{{
                      item.text
                    }}</span>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-divider class="my-5" />
          </v-col>
        </v-row>
        <v-card-actions class="mt-3">
          <v-spacer />
          <v-btn
            small
            outlined
            color="#717171"
            class="px-12 subtitle-2 text-capitalize"
            @click="visibleColumn = false"
            >Batal</v-btn
          >
          <v-btn
            small
            color="#FBB005"
            class="px-12 subtitle-2 text-capitalize"
            @click="handleApply"
            >Buat</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import SimpegLaporanService from "@/services/resources/simpeg.laporan.service";
import DataUtamaService from "@/services/resources/data.utama.service";
const ContentNotFound = () => import("@/components/Content/NotFound");

export default {
  components: {
    ContentNotFound
  },
  data() {
    return {
      format: null,
      formats: [
        {
          label: "Daftar Urutan Kepangkatan (DUK)",
          value: "duk"
        },
        { label: "Daftar Nominatif", value: "nominatif" },
        {
          label: "Daftar Usulan Kenaikan Pangkat",
          value: "naikPangkat"
        },
        {
          label: "Daftar Pegawai Memasuki Usia Pensiun",
          value: "pensiun"
        },
        { label: "Daftar Usulan KGB", value: "kgb" }
      ],
      visible: false,
      visibleFilter: false,
      visibleColumn: false,
      month: this.$moment().format("MMMM"),
      year: Number(this.$moment().format("YYYY")),
      months: [],
      years: [],
      loadingUnit: false,
      loadingExport: false,
      unit: null,
      unit1: null,
      unit2: null,
      unit3: null,
      unit4: null,
      unitsGroup: [],
      units: [],
      units1: [],
      units2: [],
      units3: [],
      units4: [],
      headers: [],
      headersActive: ["no", "NIP", "nama", "nama_jabatan", "gol"],
      limitHeader: 5,
      groupNames: ["data_diri", "kepegawaian", "pendidikan"],
      headersTemp: [],
      headersData: [
        {
          text: "No",
          value: "no",
          sortable: false,
          align: "center"
        },
        {
          text: "NIP",
          value: "NIP",
          sortable: false,
          align: "center",
          group: "data_diri"
        },
        {
          text: "Nama",
          value: "nama",
          sortable: false,
          align: "center",
          group: "data_diri"
        },
        {
          text: "Tempat Lahir",
          value: "tempat_lahir",
          sortable: false,
          align: "center",
          group: "data_diri"
        },
        {
          text: "Tanggal Lahir",
          value: "tgl_lahir",
          sortable: false,
          align: "center",
          group: "data_diri"
        },
        {
          text: "Kelamin",
          value: "kelamin",
          sortable: false,
          align: "center",
          group: "data_diri"
        },
        {
          text: "Agama",
          value: "agama",
          sortable: false,
          align: "center",
          group: "data_diri"
        },
        {
          text: "Golongan",
          value: "gol",
          sortable: false,
          align: "center",
          group: "kepegawaian"
        },
        {
          text: "TMT Golongan",
          value: "tmt_gol",
          sortable: false,
          align: "center",
          group: "kepegawaian"
        },
        {
          text: "Nama Jabatan",
          value: "nama_jabatan",
          sortable: false,
          align: "center",
          group: "kepegawaian"
        },
        {
          text: "Unit Kerja 3",
          value: "nama_unit_kerja_3",
          sortable: false,
          align: "center",
          group: "kepegawaian"
        },
        {
          text: "TMT Jabatan",
          value: "tmt_jabatans",
          sortable: false,
          align: "center",
          group: "kepegawaian"
        },
        {
          text: "Masa Kerja Tahun",
          value: "mk_tahun",
          sortable: false,
          align: "center",
          group: "pendidikan"
        },
        {
          text: "Masa Kerja Bulan",
          value: "mk_bulan",
          sortable: false,
          align: "center",
          group: "pendidikan"
        },
        {
          text: "Nama Diklat",
          value: "nama_diklat",
          sortable: false,
          align: "center",
          group: "pendidikan"
        },
        {
          text: "Tahun Diklat",
          value: "tahun_diklat",
          sortable: false,
          align: "center",
          group: "pendidikan"
        },
        {
          text: "Nama Kursus",
          value: "nama_kursus",
          sortable: false,
          align: "center",
          group: "pendidikan"
        },
        {
          text: "Tahun Kursus",
          value: "tahun_kursus",
          sortable: false,
          align: "center",
          group: "pendidikan"
        },
        {
          text: "Deskripsi Level",
          value: "deskripsi_level",
          sortable: false,
          align: "center",
          group: "pendidikan"
        },
        {
          text: "Nama Studi",
          value: "nama_studi",
          sortable: false,
          align: "center",
          group: "pendidikan"
        },
        {
          text: "Tahun Lulus",
          value: "thn_lulus",
          sortable: false,
          align: "center",
          group: "pendidikan"
        }
      ],
      totalItem: 0,
      pageCount: 0,
      loading: true,
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItem: 0,
        pageCount: 0
      },
      options: {
        sortBy: ["NIP"],
        sortDesc: [false]
      },
      rowsPerPageItems: [10, 20, 30, 40],
      employees: []
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchListDebounce(this.getListEmployee);
      },
      deep: true
    },
    type(val) {
      if (val == 0) {
        this.search = null;
      } else if (val == 1) {
        this.unit = null;
        this.unit2 = null;
        this.unit3 = null;
        this.unit4 = null;
      }
    },
    paginationProperty: function() {
      this.options.page = 1;
      this.fetchListDebounce(this.getListEmployee);
    },
    format(val) {
      if (val == "naikPangkat") {
        this.months = ["April", "Oktober"];
      } else {
        this.months = this.$moment.months();
      }
    }
  },
  methods: {
    changeUnit(type = 2) {
      if (type == 1) {
        this.units2 = this.units.find(
          d => d.unit_utama_id == this.unit
        )?.unit_kerja2;
        this.unit2 = null;
        this.unit3 = null;
        this.unit4 = null;

        // Unit Group
        this.units1 = this.unitsGroup.find(
          data => data.unit_utama_id == this.unit
        )?.unit_kerja_group;
      } else if (type == 2) {
        this.units3 = this.units2.find(
          d => d.unit_kerja_2_id == this.unit2
        )?.unit_kerja3;
        this.unit3 = null;
        this.unit4 = null;
      } else if (type == 3) {
        this.units4 = this.units3.find(
          d => d.unit_kerja_3_id == this.unit3
        )?.unit_kerja4;
        this.unit4 = null;
      }
    },
    populateUnit() {
      this.units2 = this.units.find(d =>
        d.unit_utama_id.includes(this.unit)
      )?.unit_kerja2;
      this.units3 = this.units2.find(
        d => d.unit_kerja_2_id == this.unit2
      )?.unit_kerja3;
      this.units4 = this.units3.find(
        d => d.unit_kerja_3_id == this.unit3
      )?.unit_kerja4;
    },
    handleApply() {
      // const headerActive = this.headersTemp.filter(d => d.active != null);
      // if (headerActive.length > this.limitHeader) {
      //   this.$store.commit("snackbar/setSnack", {
      //     show: true,
      //     message: `Hanya bisa memunculkan maksimal ${this.limitHeader} column`,
      //     color: "error"
      //   });
      // } else {
      this.headers = this.headersTemp.filter(d => d.active != null);
      this.visibleColumn = false;
      // }
    },
    // Service
    async getListUnitKerjaGroup() {
      try {
        this.loadingUnit = true;
        await DataUtamaService.getUnitGroupList({
          filter: {
            search: null,
            unit_utama_id: null
          },
          orderBy: ["nama_uk_group", "asc"],
          pageCurrent: 1,
          dataPerPage: 100
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.unitsGroup = data.list;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListUnit() {
      try {
        this.loadingUnit = true;
        await DataUtamaService.getUnitKerjaAll({
          platform: this.isPresensi ? "presensi" : "simpeg"
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.units = data;
              this.populateUnit();
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListEmployee() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      let service = SimpegLaporanService.laporanDUK;
      if (this.format == "duk") {
        service = SimpegLaporanService.laporanDUK;
      } else if (this.format == "nominatif") {
        service = SimpegLaporanService.laporanNominatif;
      } else if (this.format == "naikPangkat") {
        service = SimpegLaporanService.laporanNaikPangkat;
      } else if (this.format == "pensiun") {
        service = SimpegLaporanService.laporanPensiun;
      } else if (this.format == "kgb") {
        service = SimpegLaporanService.laporanKGB;
      }

      const selectedIndex = this.months.findIndex(data => data == this.month);

      try {
        this.loading = true;
        await service({
          filter: {
            bulan: selectedIndex + 1,
            tahun: this.year,
            unit_utama: this.unit || "",
            unit_kerja_2: this.unit2 || "",
            unit_kerja_3: this.unit3 || "",
            unit_kerja_4: this.unit4 || "",
            kelompok: this.unit1 || ""
          },
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, list } = response.data;
            const { data: employeeLists, last_page, total } = list;
            if (status) {
              let employees = employeeLists;
              employees.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                return data;
              });

              this.employees = employees;
              this.pagination.totalItem = parseInt(total);
              this.pagination.pageCount = parseInt(last_page);
            }
          })
          .catch(error => {
            this.$store.commit("snackbar/setSnack", {
              show: true,
              message:
                "Gagal di tampilkan, periksa kembali filter yang digunakan",
              color: "error"
            });
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async exportDataPegawai() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });

      let service = SimpegLaporanService.exportLaporanDUK;
      if (this.format == "duk") {
        service = SimpegLaporanService.exportLaporanDUK;
      } else if (this.format == "nominatif") {
        service = SimpegLaporanService.exportLaporanNominatif;
      } else if (this.format == "naikPangkat") {
        service = SimpegLaporanService.exportLaporanNaikPangkat;
      } else if (this.format == "pensiun") {
        service = SimpegLaporanService.exportLaporanPensiun;
      } else if (this.format == "kgb") {
        service = SimpegLaporanService.exportLaporanKGB;
      }

      const selectedIndex = this.months.findIndex(data => data == this.month);

      try {
        this.loadingExport = true;
        await service({
          filter: {
            bulan: selectedIndex + 1,
            tahun: this.year,
            unit_utama: this.unit,
            unit_kerja_2: this.unit2,
            unit_kerja_3: this.unit3,
            unit_kerja_4: this.unit4,
            kelompok: ""
          },
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data, message } = response.data;
            if (status) {
              const fileName = data.split("/").slice(-1);
              const link = document.createElement("a");
              link.href = data;
              link.setAttribute("download", `${fileName}`);
              document.body.appendChild(link);
              link.click();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message,
                color: "error"
              });
            }
          })
          .catch(error => {
            this.$store.commit("snackbar/setSnack", {
              show: true,
              message:
                "Gagal di tampilkan, periksa kembali filter yang digunakan",
              color: "error"
            });
            throw new Error(error);
          })
          .finally(() => (this.loadingExport = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    if (this.type == 1) {
      this.unit = null;
      this.unit2 = null;
      this.unit3 = null;
      this.unit4 = null;
    }
    this.headers = this.headersData
      .filter(d => this.headersActive.find(d2 => d2 == d.value))
      .map(d => {
        d.filterText = "";
        return d;
      });
    this.headersTemp = this.headersData.map(d => {
      const selectedItem = this.headersActive.find(d2 => d2 == d.value);
      d.active = selectedItem ? d.value : null;
      return d;
    });
    this.getListUnit();
    this.getListUnitKerjaGroup();
    if (this.$route.query && this.$route.query.tipe) {
      this.format = this.$route.query.tipe;
    }

    const years = back => {
      const year = new Date().getFullYear();
      return Array.from({ length: back }, (v, i) => year - back + i + 1);
    };

    this.years = years(3);
    this.months = this.$moment.months();
  }
};
</script>
<style lang="scss">
#btn-type .v-btn--contained {
  background-color: #ffffff !important;
  .v-btn__content {
    color: #717171 !important;
  }
}
#btn-type .v-btn--active {
  background-color: #1792e6 !important;
  .v-btn__content {
    color: #ffffff !important;
  }
}
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: 0.775rem;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
#card-custom .v-label {
  font-size: 0.775rem;
}
.v-treeview {
  font-size: 0.775rem;
}
</style>
