import BaseInstance from "../base.instance";
import {
  SIMPEG_LAPORAN_DUK,
  SIMPEG_LAPORAN_NOMINATIF,
  SIMPEG_LAPORAN_NAIK_PANGKAT,
  SIMPEG_LAPORAN_PENSIUN,
  SIMPEG_LAPORAN_KGB,
  SIMPEG_LAPORAN_DUK_EXPORT,
  SIMPEG_LAPORAN_NOMINATIF_EXPORT,
  SIMPEG_LAPORAN_NAIK_PANGKAT_EXPORT,
  SIMPEG_LAPORAN_PENSIUN_EXPORT,
  SIMPEG_LAPORAN_KGB_EXPORT
} from "../constants";

const SimpegLaporanService = {
  laporanDUK(data) {
    return BaseInstance.post(SIMPEG_LAPORAN_DUK, data);
  },
  laporanNominatif(data) {
    return BaseInstance.post(SIMPEG_LAPORAN_NOMINATIF, data);
  },
  laporanNaikPangkat(data) {
    return BaseInstance.post(SIMPEG_LAPORAN_NAIK_PANGKAT, data);
  },
  laporanPensiun(data) {
    return BaseInstance.post(SIMPEG_LAPORAN_PENSIUN, data);
  },
  laporanKGB(data) {
    return BaseInstance.post(SIMPEG_LAPORAN_KGB, data);
  },
  exportLaporanDUK(data) {
    return BaseInstance.post(SIMPEG_LAPORAN_DUK_EXPORT, data);
  },
  exportLaporanNominatif(data) {
    return BaseInstance.post(SIMPEG_LAPORAN_NOMINATIF_EXPORT, data);
  },
  exportLaporanNaikPangkat(data) {
    return BaseInstance.post(SIMPEG_LAPORAN_NAIK_PANGKAT_EXPORT, data);
  },
  exportLaporanPensiun(data) {
    return BaseInstance.post(SIMPEG_LAPORAN_PENSIUN_EXPORT, data);
  },
  exportLaporanKGB(data) {
    return BaseInstance.post(SIMPEG_LAPORAN_KGB_EXPORT, data);
  }
};

export default SimpegLaporanService;
